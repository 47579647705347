<template>
  <div>
    <!-- 门店排行 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <store-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-show="store && store.length">
          <el-radio-group v-model="chartTypes" size="mini" @change="draw">
            <el-radio label="zzt">柱状图</el-radio>
            <el-radio label="tzt">饼图</el-radio>
          </el-radio-group>
          <div class="sep-t" ref="chart" style="height:420px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <div v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.rank" class="padding-05 sep-b">
            <div class="fs-large bold padding-05">排名&nbsp;{{ o.rank }}，&nbsp;{{ o.name }}</div>
            <template v-if="isReported">
              <div class="c h">
                <div class="padding-05" style="width: 50%">
                  销售金额：{{ $price(o.realCheckSumMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData &&
                  o.compareYearData.realCheckSumMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.realCheckSumMoneyRatio
                  )
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  销售数量：{{ o.checkSaleNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSaleNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSaleNumberRatio
                  )
                  }}
                </div>
              </div>
              <div class="c h">
                <div class="padding-05" style="width: 50%">
                  发货金额：{{ $price(o.checkSendMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSendMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSendMoneyRatio
                  )
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货数量：{{ o.checkSendNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSendNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSendNumberRatio
                  )
                  }}
                </div>
              </div>
            </template>

            <template v-else>
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  销售金额：{{ $price(o.realSumMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.realSumMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.realSumMoneyRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  销售数量：{{ o.saleNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.saleNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.saleNumberRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货金额：{{ $price(o.sendMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.sendMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.sendMoneyRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货数量：{{ o.sendNumber || "-" }} 同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.sendNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.sendNumberRatio)
                  }}
                </div>
              </div>
            </template>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import storeFilter from "../assembly/filter/store";
import request from "@/utils/request";

export default {
  components: {
    storeFilter,
    filterView,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      chartTypes: "zzt",
      store: [], //图表数据
      isReported: false,
      chart: null,
      showOpt: {
        showGoodsType: true, //商品分类
        showDimension: false, //分析维度隐藏
        showYearonyear: true, //同比显示
        showCommodity: true, //指定商品
        showRangGoods: true, //商品范围
        showMonth: true, //环比隐藏
        showRank: true, //显示排名对象
        showRankGist: true, //显示排名依据
        showCompareyear: false,
        showLevel: false, //汇总级别
        showCategory: false, //对比类型
        showReported: true, //仅显示已上报
      },
      query: {
        treeNodeId: null,
        treeNodeType: null,
        dateGroup: "day", //分析维度
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null, //品牌
        seriesId: null, //系列
        categoryId: null, //分类
        goods: null, //指定商品
        reportOnly: null, //仅显示已上报
        compareYear: new Date(
          now.getFullYear() - 1,
          now.getMonth() - 1,
          now.getDate()
        ).getTime(), //同比年度
        yearOnYearShow: false, //同比
        compareMonth: false, //环比
        shopAreaGroup: "area", //排名对象
        saleValueGroup: "realSumMoney", //排名依据
        page: 0,
        size: 10000,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (!data.reportOnly) delete data.reportOnly;
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      let sd = new Date(data.compareYear);
      if (this.query.yearOnYearShow == false) {
        delete data.compareYear;
      } else if (this.query.yearOnYearShow == true) {
        data.compareYear = sd.getFullYear();
      }
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },
    loadEchart() {
      if (this.query.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/rank",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw() {
      if (this.store && this.store.length) {
        let sourceName = [];
        let sourceData1 = [];
        let sourceData2 = [];
        let sourceData3 = [];
        let binChart = [];
        let binChart2 = [];
        let binChart3 = [];
        let svgp = [];
        let groupRankName = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          sourceName.push(o.name);
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            sourceData1.push(o[this.query.saleValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            legendsvgp.push("已上报销售金额");
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            svgp.push("单品数量");
            break;
          case "checkSaleNumber":
            legendsvgp.push("已上报单品数量");
            svgp.push("已上报单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            legendsvgp.push("已上报发货金额");
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            legendsvgp.push("已上报发货数量");
            svgp.push("已上报发货数量");
            break;
        }

        switch (this.query.shopAreaGroup) {
          case "area":
            groupRankName.push("区域排名");
            break;
          case "shop":
            groupRankName.push("门店排名");
            break;
        }
        if (this.query.yearOnYearShow == true) {
          legendsvgp.push("同比");
          (this.store || []).forEach((o) => {
            if (o.compareYearData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData2.push(o.compareYearData[this.query.saleValueGroup]);
                binChart2.push({
                  name: o.name,
                  value: o.compareYearData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData2.push(
                  Math.round(
                    o.compareYearData[this.query.saleValueGroup] *
                      0.000001 *
                      100
                  ) / 100
                );
                binChart2.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compareYearData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }
        if (this.query.compareMonth == true) {
          legendsvgp.push("环比");
          (this.store || []).forEach((o) => {
            if (o.compMonthData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData3.push(o.compMonthData[this.query.saleValueGroup]);
                binChart3.push({
                  name: o.name,
                  value: o.compMonthData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData3.push(
                  Math.round(
                    o.compMonthData[this.query.saleValueGroup] * 0.000001 * 100
                  ) / 100
                );
                binChart3.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compMonthData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        binChart = (this.store || []).map((o) => {
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            return {
              name: o.name,
              value: o[this.query.saleValueGroup],
            };
          } else {
            return {
              name: o.name,
              value: o[this.query.saleValueGroup].toFixed(2) / 100,
            };
          }
        });
        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            tooltip: {
              trigger: "axis",
              confine: true,
            },
            legend: {
              data: legendsvgp,
            },
            calculable: true,
            grid: {
              left: 50,
              right: 20,
              bottom: 50,
              top: 50,
            },
            xAxis: [
              {
                type: "category",
                data: sourceName,
                axisLabel: {
                  formatter: function (value) {
                    if (value.length > 5) {
                      return value.substr(0, 5) + "…";
                    }
                    return value;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                    },
                  },
                },
              },
              sourceData2.length > 0
                ? {
                    name: "同比",
                    type: "bar",
                    data: sourceData2,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
              sourceData3.length > 0
                ? {
                    name: "环比",
                    type: "bar",
                    data: sourceData3,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
            ],
            dataZoom: {
              show: sourceName.length > 5, // 为true 滚动条出现
              filterMode: "weakFilter",
              type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
              height: 15,
              start: 0,
              end: (5 / sourceName.length) * 100,
              bottom: 10,
              handleIcon:
                "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              zoomLock: true,
              showDetail: false,
            },
          });
        } else if (this.chartTypes == "tzt") {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light");
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              left: "center",
              top: "center",
            },
            tooltip: {
              trigger: "item",
              confine: true,
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp + groupRankName,
                type: "pie",
                radius: "25%",
                center: ["50%", "18%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "同比分析",
                type: "pie",
                radius: "25%",
                center: ["50%", "50%"],
                data: binChart2.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "环比分析",
                type: "pie",
                radius: "25%",
                center: ["50%", "80%"],
                data: binChart3.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },
  },
};
</script>
